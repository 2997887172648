<template>
  <div id="container"><!--トップページのみ class="index"-->
    <section id="error404">
      <div class="wrap">
        <h2>クラス変更が必要なページです。</h2>
        <p class="alnC sp_alnL">
          こちらのページは現在のクラスではご覧いただけないページとなっております。<br>
          マイページからクラスのアップグレードを行っていただくか、前のページにお戻りください。<br><br>
          また、入塾前の方は入塾後に表示されます。入塾日以降に改めてアクセスしてください。
        </p>
        <div class="btn_wrap">
          <router-link :to="{name: 'UsersCustomerRankChange'}" class="submit green">クラスのアップグレード</router-link>
          <a @click="$router.go(-2)" class="submit light skeleton">前のページにもどる</a>&nbsp;
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
}
</script>
